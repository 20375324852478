import PaymentMethod from '@/models/PaymentMethod';
import { stringOrBlank, stringOrNull } from '@/utilities/helpers';

export default class CreditCard implements PaymentMethod {
	public cvv: null | number | string;
	public expiration: number | string;
	public is_new: boolean;
	public name: string;
	public number: number | string;
	public position: 'primary' | 'secondary' | null;
	public primary: boolean;
	public token: string;
	public issuer_id: string;
	public type: string;
	public zip: string;
	public api_key: string;
	public subLocation: number;
	public source: string;
	public processor: string;
	public cardshieldticket: string;
	public errors: any;

	public deleted: boolean = false;

	public get payment_type():string {
		return 'cc';
	}

	public constructor(data: any = {}) {
		this.cvv = data.cvv;
		this.expiration = data.expiration;
		this.is_new = data.is_new;
		this.name = stringOrBlank(data.name);
		this.number = data.number;
		this.position = stringOrNull(data.position);
		this.primary = data.primary;
		this.token = data.token || '';
		this.issuer_id = data.issuer_id || '';
		this.type = stringOrBlank(data.type);
		this.zip = data.zip;
		this.api_key = data.api_key || '';
		this.subLocation = data.subLocation || 0;
		this.source = data.source || 'website';
		this.processor = data.processor || '';
		this.cardshieldticket = data.cardshieldticket || '';
	}
}
